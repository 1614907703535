import { render, staticRenderFns } from "./ApprovedDialog.vue?vue&type=template&id=2307cce0&"
import script from "./ApprovedDialog.vue?vue&type=script&lang=js&"
export * from "./ApprovedDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.3_b61fd53126d8a76b25cc5244d186282f/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.6.0_9053c641aa08e2db0287c4c8a8df6e99/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VDialog,VRow})
