<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card style="border-radius: 6px">
      <v-card-text>
        <v-row no-gutters>
          <v-col class="text-center mt-3" cols="12">
            <h1 class="main-title">{{ $t("great") }}</h1>
            <p class="py-3">
              {{ purchaseType === 'event' ? $t('yourAccessHasBeenApproved') : $t('yourPaymentHasBeenApproved') }}
            </p>
          </v-col>

          <v-col class="text-center" cols="12">
            <v-btn @click="close" large outlined>
              {{ purchaseType === 'event' ?
                (isOnlineEvent ?
                  $t('accessEvent') : $t('downloadYourTickets')) :
                $t('goToMyPurchases')
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  props: {
    value: {
      type: Boolean
    },
    purchaseType: {
      default: 'event' // event || product
    },
    isOnlineEvent: {
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('approved')
      this.show = false
    }
  }
}
</script>
