const rules = function ($t) {
  const rules = {
    required: (value) => !!value || $t('informationNeeded'),
    email: (value) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!value) return true
      return pattern.test(value) || $t('invalidEmailAddress')
    },
    url: value => {
      // https://regexr.com/3e6m0
      // eslint-disable-next-line
      const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]{4}\/*/
      if (value === '') return true
      return pattern.test(value) || $t('invalidUrl')
    },
    min8: (value) => {
      if (value) return value.length >= 8 || $t('passwordMin8Length')
      else return true
    },
    minLength: (value, minLength) => {
      if (value) return value.length >= minLength || $t('passwordMinLength')
      else return true
    },
    onlyNumbers: (value) => {
      const pattern = /^[0-9]+$/
      return pattern.test(value) || $t('onlyNumbers')
    }
  }
  return rules
}

export default rules
