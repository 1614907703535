<template>
  <v-dialog :value="value" max-width="800px" persistent scrollable>
    <v-card class="pb-0">
      <v-toolbar class="px-3" dark text :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <div class="title">
          {{$t('setPaymentData')}}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelTransaction()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :style="{'background-color': ($vuetify.theme.dark ? '#1e1e1e' : '#ffffff'),
                            'color': ($vuetify.theme.dark ? '#ffffff' : '#1e1e1e')}">
        <v-form ref="paymentUserInfoForm" lazy-validation>
          <v-row>
            <v-col class="pt-6 pb-2" cols="12">
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="Email"
                placeholder="usuario@keybe.ai"
                prepend-icon="mdi-email-outline"
                dense
                outlined
                class="field-color"
              ></v-text-field>
            </v-col>
            <v-col class="py-2" cols="12">
              <v-autocomplete
                v-model="docType"
                :items="docTypes"
                :rules="[rules.required]"
                :label="$t('docType')"
                prepend-icon="mdi-account-convert"
                item-text="name"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                class="field-color"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-2" cols="12">
              <v-text-field
                v-model="docNumber"
                :rules="[rules.required, rules.onlyNumbers]"
                :label="$t('docNumber')"
                prepend-icon="mdi-card-account-details-outline"
                dense
                outlined
                class="field-color"
              ></v-text-field>
            </v-col>
            <v-col class="py-2" cols="12">
              <v-autocomplete
                v-model="typePerson"
                :items="typePersons"
                :rules="[rules.required]"
                :label="$t('personType')"
                prepend-icon="mdi-briefcase-account-outline"
                item-text="text"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                class="field-color"
              ></v-autocomplete>
            </v-col>
            <template v-if="pseBanks.length">
              <v-col class="pt-2 pb-0" cols="12">
                <v-autocomplete
                  v-model="bank"
                  :items="pseBanks"
                  :rules="[rules.required]"
                  item-text="financial_institution_name"
                  item-value="financial_institution_code"
                  :label="$t('bank')"
                  prepend-icon="mdi-bank-outline"
                  outlined
                  dense
                  chips
                  small-chips
                  class="field-color"
                ></v-autocomplete>
              </v-col>
            </template>

            <v-col
              cols="12" class="py-0"
            >
              <v-checkbox
                v-model="acceptedTerms"
                dense outlined
              >
                <template v-slot:label>
                  <div>
                    {{$t('accept')}}
                    <a
                      target="_blank"
                      :href="acceptancePermalink"
                      @click.stop
                    >{{$t('termsAndConditions')}}</a>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <v-spacer></v-spacer>
        <v-btn color="red"
               class="mr-1"
               outlined
               @click="cancelTransaction()">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="submitDisabled"
          @click="submit()"
          color="kbGreen"
        >
          {{ $t('accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import rules from '@/utils/rules'
export default {
  computed: {
    ...mapState({
      pseBanks: (state) => state.money.pseBanks,
      docTypes: (state) => state.money.docTypes,
      acceptancePermalink: (state) => state.money.acceptancePermalink
    }),
    submitDisabled () {
      return !(this.email && this.acceptedTerms)
    },
    rules () {
      return rules(this.$t.bind(this))
    }
  },
  data () {
    return {
      typePersons: [
        {
          // id: 'individual', // mercadopago
          id: '0', // wompi
          text: 'Persona'
        },
        {
          // id: 'association', // mercadopago
          id: '1', // wompi
          text: 'Empresa'
        }
      ],
      email: '',
      docType: '',
      docNumber: '',
      typePerson: '',
      bank: '',
      acceptedTerms: false
    }
  },
  created () {
    const { document = '', email = '' } = this.mainAttendee
    this.email = email
    this.docNumber = document
    // TODO: Get stored info from user extradata:
    // this.email = paymentUserEmail
  },
  methods: {
    setPaymentInfo (info) {
      this.$emit('setPaymentInfo', info)
    },
    submit () {
      if (!this.$refs.paymentUserInfoForm.validate()) return
      this.setPaymentInfo({
        email: this.email,
        docType: this.docType,
        docNumber: this.docNumber,
        typePerson: this.typePerson,
        bank: this.bank
      })
      this.$emit('input', false)
      this.$emit('confirmPaymentInfo')
    },
    cancelTransaction () {
      this.$emit('cancelTransaction')
      this.$emit('setPaymentInfo', {})
      this.$emit('input', false)
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    mainAttendee: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
