<template>
  <v-dialog :value="value" max-width="800px" persistent scrollable>
    <v-card dark class="pb-0">
      <v-toolbar class="px-3" dark text :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <div class="title">
          {{$t('selectCreditCardForPayment')}}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelTransaction()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :style="{'background-color': $vuetify.theme.dark ? '#1e1e1e' : '#ffffff'}">
        <v-row>
            <v-col class="pa-4" cols="12" v-if="creditCards.length > 0" >
                <v-row dense>
                    <v-col md="12" lg="6" xl="4" v-for="(creditCard, index) in creditCards" :key="index" class="my-1">

                        <v-card @click="setSelectedCreditCard(index)" :class="['grey', 'darken-4', creditCard.id === selectedCreditCard.id && 'elevation-20']" style="border-radius: 10px">
                            <v-card-text style="box-sizing: border-box">
                                <div style="display: flex; align-items: center; flex-wrap: wrap">
                                    <div class="xs12">
                                        <img :src="require(`@/assets/credit_cards_material_design/png/72/${getImageFileName(creditCard.brand)}.png`)"
                                              height="72px">
                                    </div>

                                    <div class="xs12" style="padding: 10px; width: 100%;">
                                        <div class="headline">
                                            {{ creditCard.brand }}
                                        </div>

                                        <div>
                                            {{$t('lastDigits')}}: {{ creditCard.last4Digits }}
                                        </div>

                                        <div v-if="creditCard.expMonth && creditCard.expYear">
                                            {{$('cardExpirationDate')}}: {{ creditCard.expMonth }} / {{ creditCard.expYear }}
                                        </div>

                                        <div>
                                          <v-chip
                                            color="kbLightBlue"
                                            v-if="isAmexNeedCopConversion"
                                            dark
                                            x-large
                                          >
                                            <p style="font-size: 11px; width: 100%; display: inline-grid;">
                                            <span>
                                              Para tarjetas American Express, el cobro es realizado en dólares
                                              por un valor de:
                                            </span>
                                            <span class="font-weight-black">USD ${{copUsdConversion}}</span>
                                            <span>
                                              (COP {{ formatCurrency(totalPayAmount) }})
                                            </span>
                                            </p>

                                            <!-- tam 11
                                            rad 4
                                            pad 10 -->

                                          </v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-chip color="kbLightBlue"
                                        dark
                                        v-if="creditCard.priority === 0">
                                    {{ $t('mainCard') }}
                                </v-chip>

                                <span v-if="creditCard.priority !== 0">
                                  <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                          <v-btn class="hidden-sm-and-up"
                                                  @click="updateCreditCardSetMainCard(creditCard)"
                                                  color="kbGrayText"
                                                  text
                                                  icon
                                                  v-on="on">
                                              <v-icon>
                                                  mdi-credit-card-settings
                                              </v-icon>
                                          </v-btn>
                                      </template>

                                      <span>{{$t('changeToPrincipal')}}</span>
                                  </v-tooltip>

                                  <v-btn rounded
                                          text
                                          class="hidden-xs-only"
                                          color="kbGrayText"
                                          @click="updateCreditCardSetMainCard(creditCard)">
                                      {{$t('changeToPrincipal')}}
                                      <v-icon dark
                                              right>
                                          mdi-credit-card-settings
                                      </v-icon>
                                  </v-btn>

                                  <v-btn icon
                                          text
                                          color="error"
                                          @click="deleteCreditCard(creditCard)"
                                          :loading="deletingCard === creditCard.id">
                                      <v-icon dark>delete</v-icon>
                                  </v-btn>
                              </span>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="text-center" @click="openAddCreditCardDialog()"
                    cols="12"
                    v-if="creditCards.length === 0">
                <div class="add-credit-card-button"
                      v-ripple>
                    <v-icon color="kbDarkBlue" size="60px">
                        mdi-credit-card-plus
                    </v-icon>
                    <br>
                    <span class="title kbDarkBlue--text">
                      {{$t('addCreditCard')}}

                    </span>
                </div>
            </v-col>

            <v-col class="text-right" cols="12" v-else>
                <v-btn @click="openAddCreditCardDialog()"
                        color="kbBlue"
                        dark
                        rounded>
                    {{$t('addCreditCard')}}
                    <v-icon right dark>mdi-credit-card-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <v-spacer></v-spacer>
        <v-btn color="red"
               class="mr-1"
               outlined
               @click="cancelTransaction()">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          color="kbGreen"
          @click="submit()"
          :disabled="!selectedCreditCard.id"
        >
          {{ $t('pay') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import bankBrandImages from '@/utils/bankBrandImages'
import formatCurrency from '@/mixins/formatCurrency'

import notifications from '@/plugins/notifications'
export default {
  computed: {
    ...mapState({
      creditCards: state => state.money.creditCards,
      appInfo: state => state.authKeybe.appInfo,
      selectedEvent: state => state.events.selectedEvent
    }),
    getImageFileName () {
      return this.bankBrandImages.getImageFileName
    },
    selectedCreditCard: {
      get () {
        return this.$store.state.money.selectedCreditCard
      },
      set (value) {
        this.$store.commit('money/SET_SELECTED_CREDIT_CARD', value)
      }
    }
  },
  mixins: [ formatCurrency ],
  data () {
    return {
      deletingCard: '',
      isAmexNeedCopConversion: false,
      copUsdConversion: 0,
      bankBrandImages
    }
  },
  mounted () {
    this.checkIsAmexNeedCopConversion()
  },
  methods: {
    openAddCreditCardDialog () {
      this.$emit('openAddNewCreditCardDialog')
    },
    async deleteCreditCard (card) {
      try {
        console.log('card')
        console.log(card)
        this.deletingCard = card.id
        await this.$store.dispatch('money/deleteCard', card.id)
        // notifications.success(this.$t('general.creditCardSuccessfullyDeleted'))
        notifications.success('Credit Card Successfully Deleted')
        this.deletingCard = ''
        this.selectedCreditCard = {}
      } catch (e) {
        console.error(e)
        // notifications.error(this.$t('general.errorDeletingCreditCard'))
        notifications.error('Error Deleting Credit Card')
        this.deletingCard = ''
      }
    },
    async updateCreditCardSetMainCard (card) {
      try {
        this.updatingCardPriority = card.id
        await this.$store.dispatch('money/setCardAsMain', card.id)
        this.updatingCardPriority = ''
        // notifications.success(this.$t('general.creditCardPriorityUpdated'))
        notifications.success('Credit Card Priority Updated')
      } catch (e) {
        this.updatingCardPriority = ''
        console.error(e)
      }
    },
    setSelectedCreditCard (index) {
      this.selectedCreditCard = this.creditCards[index]
    },
    checkIsAmexNeedCopConversion () {
      // console.log('this.selectedCreditCard')
      // console.log(this.selectedCreditCard)
      // console.log('this.selectedEvent')
      // console.log(this.selectedEvent)
      // Check event is in cop
      if (this.selectedEvent.currency && this.selectedEvent.currency === 'cop') {
        // Check is amex
        if (this.selectedCreditCard.brand && this.selectedCreditCard.brand &&
          (this.selectedCreditCard.brand.toLowerCase() === 'american express' ||
          this.selectedCreditCard.brand.toLowerCase() === 'ax')) {
          if (this.appInfo && this.appInfo.extraData) {
            const appExtradata = this.appInfo.extraData
            let exchangeRateSettings = appExtradata.find(e => e.field === 'MARKET_REPRESENTATIVE_EXCHANGE_RATE_USD_COP')
            let exchangeRateValue = 0
            if (exchangeRateSettings.value !== undefined) {
              exchangeRateValue = parseInt(exchangeRateSettings.value)
            }
            // console.log('exchangeRateSettings')
            // console.log(exchangeRateSettings)
            // console.log('exchangeRateValue')
            // console.log(exchangeRateValue)
            if (exchangeRateValue) {
              this.isAmexNeedCopConversion = true
              this.copUsdConversion = Math.round(((this.totalPayAmount /
                exchangeRateValue) + Number.EPSILON) * 100) / 100
              // console.log('this.copUsdConversion')
              // console.log(this.copUsdConversion)
            }
          }
        }
      }
    },
    submit () {
      this.$emit('cardSelected')
      this.$emit('input', false)
    },
    cancelTransaction () {
      this.$emit('cancelTransaction')
      this.selectedCreditCard = {}
      this.$emit('input', false)
    }
  },
  watch: {
    selectedCreditCard: {
      deep: true,
      handler () {
        this.checkIsAmexNeedCopConversion()
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    totalPayAmount: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style>
    .add-credit-card-button {
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 10px 0;
    }
</style>
