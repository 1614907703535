<template>
  <v-dialog :value="value" max-width="800px" persistent scrollable>
    <v-card dark class="pb-0">
      <v-toolbar class="px-3" dark text :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelTransaction()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :style="{'background-color': $vuetify.theme.dark ? '#1e1e1e' : '#ffffff'}">
        <v-row>
          <v-col cols="12" class="pb-0 pt-6">
            <span class="d-block d-md-none text-centert"
                  style="font-weight: 800"
                  :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">
              {{ $t('selectPaymentMethod') | uppercase() }}
            </span>
            <h3 class="d-none d-md-block text-center"
                style="font-weight: 800"
                :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">
              {{ $t('selectPaymentMethod') | uppercase() }}
            </h3>
          </v-col>
            <v-col class="pa-4" cols="12">
                <v-row dense>
                    <v-col cols="6" class="my-1 align-self-stretch">
                        <PaymentMethodButton payment-method="creditCard"
                                             :selected-payment-method="selectedPaymentMethod"
                                             @setSelectedPaymentMethod="setSelectedPaymentMethod" />
                    </v-col>
                    <v-col cols="6" class="my-1 align-self-stretch">
                      <PaymentMethodButton payment-method="pse"
                                           :selected-payment-method="selectedPaymentMethod"
                                           @setSelectedPaymentMethod="setSelectedPaymentMethod" />
                    </v-col>
                    <!-- <v-col md="12" lg="6" xl="4" class="my-1">
                        <v-card @click="setSelectedPaymentMethod('cash')" :class="['grey', 'darken-4', selectedPaymentMethod === 'cash' && 'elevation-20']" style="border-radius: 15px">
                            <v-card-text style="box-sizing: border-box">
                                <div style="display: flex; align-items: center; flex-wrap: wrap">

                                    <div class="xs12" style="padding: 10px">
                                        <div class="headline">
                                            Cash
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="12" lg="6" xl="4" class="my-1">
                        <v-card @click="setSelectedPaymentMethod('mercadopago')" :class="['grey', 'darken-4', selectedPaymentMethod === 'mercadopago' && 'elevation-20']" style="border-radius: 15px">
                            <v-card-text style="box-sizing: border-box">
                                <div style="display: flex; align-items: center; flex-wrap: wrap">

                                    <div class="xs12" style="padding: 10px">
                                        <div class="headline">
                                            Mercadopago
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col> -->
                </v-row>
                <v-row dense>
                    <v-col md="12" class="my-1">
                        <v-card :style="{'background-color': $vuetify.theme.dark ? '#1e1e1e' : '#f0f4fd'}" style="border-radius: 15px">
                            <v-card-text style="box-sizing: border-box">
                              <v-row dense justify="center">
                                  <v-col cols="6" style="padding-right: 10px;">
                                    <p v-if="numberAttendees !== undefined" class="text-h6 text-right"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{ $tc('attendee', numberAttendees) }}:</p>
                                    <p v-if="numberAttendees !== undefined" class="text-h6 text-right"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{ $t('ticketService') }}:</p>
                                    <p class="text-h6 text-right font-weight-bold"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{ $t('totalPayAmount') }}:</p>
                                  </v-col>
                                  <v-col cols="6" style="border-left-width: 1px; border-left-style: solid; padding-left: 10px;">
                                    <p v-if="numberAttendees !== undefined" class="text-h6 text-uppercase"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{currency}} {{ formatCurrency(totalTicketPrice)}}</p>
                                    <p v-if="numberAttendees !== undefined" class="text-h6 text-uppercase"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{currency}} {{ formatCurrency(totalServicePrice) }}</p>
                                    <p class="text-h6 text-uppercase font-weight-bold"
                                       :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">{{currency}} {{ formatCurrency(totalPayAmount) }}</p>
                                  </v-col>
                                  <v-col cols="5" md="2" class="d-flex align-center pt-6">
                                    <v-img :src="'https://storage.googleapis.com/keybe/kb.live/img/event-purchase-confirmation-ticket-info.png'"
                                           style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"/>
                                  </v-col>
                              </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <v-spacer></v-spacer>
        <v-btn color="red"
               class="mr-1"
               outlined
               @click="cancelTransaction()">
          {{$t('cancel')}}
        </v-btn>

        <v-btn
          color="kbGreen"
          @click="submit()"
          :disabled="!selectedPaymentMethod"
        >
          {{$t('accept')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import formatCurrency from '@/mixins/formatCurrency'
import PaymentMethodButton from './PaymentMethodButton'
export default {
  components: { PaymentMethodButton },
  computed: {
    ...mapState({
    }),
    totalTicketPrice () { return ((this.selectedTicket && this.selectedTicket.price) || 0) * this.numberAttendees || 0 },
    totalServicePrice () { return ((this.selectedTicket && this.selectedTicket.servicePrice) || 0) * this.numberAttendees || 0 },
    totalPayAmount () {
      return (this.totalTicketPrice || 0) + (this.totalServicePrice || 0) + (this.totalPriceCartProducts || 0)
    }
  },
  data () {
    return {
    }
  },
  mixins: [formatCurrency],
  created () {
    // console.log(this.selectedTicket)
    console.log('Keybe!')
  },
  methods: {
    setSelectedPaymentMethod (method) {
      this.$emit('setSelectedPaymentMethod', method)
    },
    submit () {
      this.$emit('input', false)
      this.$emit('confirmSelectedPaymentMethod')
    },
    cancelTransaction () {
      this.$emit('cancelTransaction')
      this.$emit('setSelectedPaymentMethod', '')
      this.$emit('input', false)
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedPaymentMethod: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      default: 'usd'
    },
    selectedTicket: {
    },
    numberAttendees: {
      type: Number
    },
    totalPriceCartProducts: {
      type: Number
    }
  }
}
</script>
