<template>
  <v-dialog max-width="500px" v-model="show" scrollable>
    <v-card style="border-radius: 6px; position: relative">
      <v-card-text style="box-sizing: border-box">
        <v-row no-gutters>
          <v-col class="text-center my-4" cols="12">
            {{ message }}
          </v-col>

          <v-col class="text-center">
            <v-btn @click="close" large outlined>
              <!-- {{ $t("ok") }} -->
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    close () {
      // this.$router.back()
      window.keybe.openChat()
      this.show = false
    }
  },
  props: {
    message: {
      type: String,
      required: true
    },
    value: {
      type: Boolean
    }
  }
}
</script>
