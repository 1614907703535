<template>
  <v-dialog :value="value" max-width="800px" persistent scrollable>
    <v-card dark class="pb-0">
      <v-toolbar class="px-3" dark flat :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <div class="title">
          {{$t('addCreditCard')}}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelTransaction()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12"
                   class="d-flex justify-center">
              <div class="d-flex flex-column align-center">
                <v-icon
                        class="mb-1"
                        size="80px">
                  mdi-credit-card
                </v-icon>
                <span class="white--text text-h5 font-weight-medium">
                {{ $t('addCreditCard') }}
              </span>
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="cardNumber"
                :rules="[rules.required]"
                :label="$t('cardNumber')"
                v-mask="'#### #### #### #### ####'"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="cardExpirationDate"
                :rules="[rules.required]"
                :label="$t('cardExpirationDate')"
                v-mask="'##/##'"
                placeholder="MM/YY"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="securityCode"
                :rules="[rules.required]"
                :label="$t('securityCode')"
                v-mask="'####'"
                type="password"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="cardholderName"
                :rules="[rules.required]"
                :label="$t('cardholderName')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="cardholderLastName"
                :rules="[rules.required]"
                :label="$t('cardholderLastName')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="Email"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                v-model="cardholderIdentificationType"
                :items="docTypes"
                :rules="[rules.required]"
                :label="$t('cardholderIdentificationType')"
                item-text="name"
                item-value="id"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="cardholderIdentificationNumber"
                :rules="[rules.required, rules.onlyNumbers]"
                :label="$t('cardholderIdentificationNumber')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="issuers.length"
              cols="12" class="pb-0"
            >
              <v-autocomplete
                v-model="selectedIssuerId"
                :items="issuers"
                :rules="[rules.required]"
                :label="$t('issuer')"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12" class="pb-0"
            >
              <v-checkbox
                v-model="acceptedTerms"
                dense outlined
              >
                <template v-slot:label>
                  <div>
                    {{$t('accept')}}
                    <a
                      target="_blank"
                      :href="acceptancePermalink"
                      @click.stop
                    >{{$t('termsAndConditions')}}</a>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red"
               class="mr-1"
               outlined
               @click="cancelTransaction()">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="submitDisabled"
          dark
          color="kbGreen"
          @click="submit()"
          :loading="loadingAddCreditCard">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'

import rules from '@/utils/rules'
import notifications from '@/plugins/notifications'
export default {
  components: { },
  directives: { mask },
  computed: {
    ...mapState({
      docTypes: (state) => state.money.docTypes,
      acceptanceToken: (state) => state.money.wompiAcceptanceToken,
      acceptancePermalink: (state) => state.money.acceptancePermalink
    }),
    submitDisabled () {
      return !(this.cardholderIdentificationNumber && this.acceptedTerms)
    },
    rules () {
      return rules(this.$t.bind(this))
    }
  },
  data () {
    return {
      cardNumber: '',
      cardExpirationDate: '',
      securityCode: '',
      loadingAddCreditCard: false,
      issuers: [],
      selectedIssuerId: '',
      cardholderName: '',
      cardholderLastName: '',
      email: '',
      cardholderIdentificationNumber: '',
      cardholderIdentificationType: '',
      acceptedTerms: false
    }
  },
  created () {
    this.getAcceptanceToken()
    this.$store.dispatch('money/getIdentificationTypes')

    const { document = '', email = '' } = this.mainAttendee
    this.email = email
    this.cardholderIdentificationNumber = document
  },
  methods: {
    clearInputs () {
      this.$refs.form.reset()
    },
    async getAcceptanceToken () {
      await this.$store.dispatch('money/getAcceptanceToken')
    },
    async submit () {
      try {
        if (!this.$refs.form.validate()) return
        const cardNumber = this.cardNumber.replaceAll(' ', '')

        try {
          // console.log('additional_info_needed')
          // console.log(response[0].additional_info_needed)
          this.loadingAddCreditCard = true

          const cardExpirationDate = this.cardExpirationDate.split('/')
          await this.$store.dispatch('money/addCreditCard', {
            cardNumber,
            cardExpirationMonth: cardExpirationDate[0],
            cardExpirationYear: cardExpirationDate[1],
            securityCode: this.securityCode,
            cardholderName: this.cardholderName,
            cardholderLastName: this.cardholderLastName,
            email: this.email,
            docNumber: this.cardholderIdentificationNumber,
            docType: this.cardholderIdentificationType,
            acceptanceToken: this.acceptanceToken
          })
          this.loadingAddCreditCard = false
          notifications.success(this.$t('creditCardSuccessfullyAdded'))
          // notifications.success('Credit card successfully added')
          this.$emit('creditCardAdded')
          this.toggleModal()
        } catch (error) {
          console.error(error)
          notifications.error('We could not validate your credit card')
          this.loadingAddCreditCard = false
        }
      } catch (error) {
        console.error(error)
        notifications.error('We could not validate your credit card')
        this.loadingAddCreditCard = false
        // console.log(e)
      }
    },
    // getInstallments (paymentMethodId, transactionAmount, selectedIssuerId) {

    // },
    cancelTransaction () {
      this.clearInputs()
      this.$emit('cancelTransaction')
      this.toggleModal()
    },
    toggleModal () {
      this.$emit('input', false)
      this.clearInputs()
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mainAttendee: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
