<template>
  <div class="elevation-15 d-flex justify-center align-center pa-3"
       :style="{'background-color': $vuetify.theme.dark ?
                                    selectedPaymentMethod === paymentMethod ?
                                    '#424242' : '#1e1e1e' :
                                    selectedPaymentMethod === paymentMethod ?
                                    '#c5cbd9' : '#f0f4fd'}"
       style="border-radius: 15px; cursor: pointer; height: 100%"
       @click="setSelectedPaymentMethod(paymentMethod)">
    <span class="heading text-center d-block"
          :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#272727'}">
      {{ paymentMethod === 'creditCard' ? $t('creditCard') : 'PSE' }}
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    setSelectedPaymentMethod (method) {
      this.$emit('setSelectedPaymentMethod', method)
    }
  },
  props: {
    selectedPaymentMethod: {
      type: String,
      required: true,
      validators: (value) => {
        return ['pse', 'creditCard'].indexOf(value) !== -1
      }
    },
    paymentMethod: {
      type: String,
      required: true,
      validators: (value) => {
        return ['pse', 'creditCard'].indexOf(value) !== -1
      }
    }
  }
}
</script>
