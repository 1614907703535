const bankBrandImages = {}
bankBrandImages.getImageFileName = function (brand = '') {
  if (brand === null) brand = ''
  brand = brand.toLowerCase()
  switch (brand) {
    case 'mastercard':
    case 'mc':
      return 'mastercard'
    case 'visa':
    case 'vi': case 'vs':
      return 'visa'
    case 'jcb':
      return 'jcb'
    case 'american express':
    case 'ax':
      return 'american_express'
    case 'diners club':
      return 'diners_club'
    case 'discover':
      return 'discover'
    case 'unionpay':
      return 'china_union'
    default:
      return 'credit_card'
  }
}
export default bankBrandImages
